<template>
  <div class="d-flex justify-center align-center">
    <slot>
      <img :src="src" :alt="alt" width="35%" height="35%" />
    </slot>
  </div>
</template>

<script>
export default {
  name: 'CenteredIcon',
  props: {
    src: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    }
  }
};
</script>
