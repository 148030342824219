<template>
  <home-sidebar-select-element :mini-variant="miniVariant">
    <template v-slot:mini-icon>
      <centered-icon class="sidebar-select__icon-wrapper">
        <v-avatar :size="34">
          <img src="@/assets/images/home/sidebar/default-user-avatar.jpg" alt="User avatar" />
        </v-avatar>
      </centered-icon>
    </template>
    <template v-slot:expand-icon>
      <centered-icon class="sidebar-select__icon-wrapper">
        <v-avatar class="sidebar-select__icon-wrapper" :size="34">
          <img src="@/assets/images/home/sidebar/default-user-avatar.jpg" alt="User avatar" />
        </v-avatar>
      </centered-icon>
      <div class="sidebar-select__title-wrapper d-flex flex-column justify-center">
        <span class="account-link indigo--text text--lighten-1 mb-2">Account</span>
        <button class="text-left account-link indigo--text text--lighten-1" @click="onLogOut">
          Log out
        </button>
      </div>
    </template>
  </home-sidebar-select-element>
</template>

<script>
import { mapMutations } from 'vuex';

import HomeSidebarSelectElement from './HomeSidebarSelectElement.vue';
import CenteredIcon from '../common/CenteredIcon.vue';

import types from '@/store/types';

export default {
  name: 'AccountSidebarSelectElement',
  components: { CenteredIcon, HomeSidebarSelectElement },
  props: {
    miniVariant: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapMutations({
      resetStore: types.mutations.RESET_STORE
    }),
    onLogOut() {
      this.resetStore();
      this.$router.push({ name: 'SignIn' });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/home/sidebar-select';
</style>
