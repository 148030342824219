<template>
  <div class="sidebar-select" @click="selectSidebarElement">
    <slot name="mini-icon">
      <centered-icon
        class="sidebar-select__icon-wrapper"
        :class="{ 'blue-grey darken-3': selected }"
        :src="iconSrc"
        :alt="selectElement.iconAlt"
      />
    </slot>
    <div
      v-if="!miniVariant"
      class="sidebar-select__hovered-state white d-flex"
      :class="{ 'blue-grey darken-3': selected }"
    >
      <slot name="expand-icon">
        <centered-icon
          class="sidebar-select__icon-wrapper"
          :src="iconSrc"
          :alt="selectElement.iconAlt"
        />
        <div class="sidebar-select__title-wrapper d-flex align-center">
          <span class="blue-grey--text text--lighten-1">{{ selectElement.title }}</span>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import CenteredIcon from '../common/CenteredIcon.vue';

export default {
  name: 'HomeSidebarSelectElement',
  components: { CenteredIcon },
  props: {
    selectElement: {
      type: Object,
      default: () => ({})
    },
    miniVariant: {
      type: Boolean,
      default: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconSrc() {
      return `/icons/${this.selectElement.icon}.svg`;
    }
  },
  methods: {
    selectSidebarElement() {
      if (!this.selected) {
        this.$emit('select-element', this.selectElement);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/home/sidebar-select';
</style>
