<template>
  <v-navigation-drawer
    class="home-sidebar grey lighten-5"
    app
    permanent
    expand-on-hover
    width="188px"
    :mini-variant.sync="miniVariant"
  >
    <template v-slot:prepend>
      <div class="home-sidebar__select-element d-flex align-center justify-center">
        <img src="@/assets/icons/logo.svg" alt="Logo" />
      </div>
    </template>
    <home-sidebar-select-element
      v-for="selectElement in homeSidebarSelects"
      :key="selectElement.pageName"
      :select-element="selectElement"
      :mini-variant="miniVariant"
      :selected="selectedSidebarElementPageName === selectElement.pageName"
      @select-element="selectSidebarElement"
    />
    <template v-slot:append>
      <notifications-sidebar-select-element :mini-variant="miniVariant" />
      <account-sidebar-select-element :mini-variant="miniVariant" />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';

import HomeSidebarSelectElement from './HomeSidebarSelectElement.vue';
import NotificationsSidebarSelectElement from './NotificationsSidebarSelectElement.vue';
import AccountSidebarSelectElement from './AccountSidebarSelectElement.vue';

import types from '@/store/types';

const SIDEBAR_PAGE = {
  DASHBOARD: 'Dashboard',
  USERS: 'Users',
  REGIONS: 'Regions',
  PRODUCTS: 'Products',
  ORDERS: 'Orders',
  CAMPAIGNS: 'Campaigns',
  PROMO_CODES: 'PromoCodes',
  STATISTIC_AND_LOGISTIC: 'StatisticsAndLogs',
  SCHEDULING_TASKS: 'SchedulingTasks'
};

const SIDEBAR_PAGES_NAMES = Object.values(SIDEBAR_PAGE);

const HOME_SIDEBAR_SELECTS = [
  {
    title: 'Dashboard',
    icon: 'vertical-lines-icon',
    iconAlt: 'Dashboard icon',
    pageName: SIDEBAR_PAGE.DASHBOARD
  },
  {
    title: 'Users',
    icon: 'users-icon',
    iconAlt: 'Users icon',
    pageName: SIDEBAR_PAGE.USERS
  },
  {
    title: 'Regions',
    icon: 'regions-icon',
    iconAlt: 'Regions icon',
    pageName: SIDEBAR_PAGE.REGIONS
  },
  {
    title: 'Products',
    icon: 'product-icon',
    iconAlt: 'Products icon',
    pageName: SIDEBAR_PAGE.PRODUCTS
  },
  {
    title: 'Orders',
    icon: 'order-icon',
    iconAlt: 'Orders icon',
    pageName: SIDEBAR_PAGE.ORDERS
  },
  {
    title: 'Campaigns',
    icon: 'marketing-campaigns-icon',
    iconAlt: 'Campaigns icon',
    pageName: SIDEBAR_PAGE.CAMPAIGNS
  },
  {
    title: 'Promo codes',
    icon: 'promo-codes-icon',
    iconAlt: 'Promo codes icon',
    pageName: SIDEBAR_PAGE.PROMO_CODES
  },
  {
    title: 'Statistics and Logs',
    icon: 'statistics-logs-icon',
    iconAlt: 'Statistics and Logs icon',
    pageName: SIDEBAR_PAGE.STATISTIC_AND_LOGISTIC
  },
  {
    title: 'Scheduling Tasks',
    icon: 'schedule',
    iconAlt: 'Scheduling Tasks icon',
    pageName: SIDEBAR_PAGE.SCHEDULING_TASKS
  }
];

export default {
  name: 'HomeSidebar',
  components: {
    AccountSidebarSelectElement,
    NotificationsSidebarSelectElement,
    HomeSidebarSelectElement
  },
  data() {
    return {
      miniVariant: true,
      homeSidebarSelects: []
    };
  },
  computed: {
    ...mapGetters({
      isSuperAdmin: types.getters.IS_SUPER_ADMIN
    }),
    selectedSidebarElementPageName() {
      const route = this.$route.matched.find(matchedRoute =>
        SIDEBAR_PAGES_NAMES.includes(matchedRoute.name)
      );

      return route ? route.name : '';
    }
  },
  created() {
    if (this.isSuperAdmin) {
      this.homeSidebarSelects = HOME_SIDEBAR_SELECTS;

      return;
    }

    this.homeSidebarSelects = HOME_SIDEBAR_SELECTS.filter(
      ({ pageName }) =>
        pageName !== SIDEBAR_PAGE.STATISTIC_AND_LOGISTIC &&
        pageName !== SIDEBAR_PAGE.SCHEDULING_TASKS
    );
  },
  methods: {
    selectSidebarElement({ pageName }) {
      this.$router.push({ name: pageName });
    }
  }
};
</script>

<style lang="scss" scoped>
.home-sidebar {
  overflow: visible;

  &__select-element {
    width: 80px;
    height: 80px;
  }
}
</style>
