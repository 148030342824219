<template>
  <div class="home-page">
    <home-sidebar class="home-page__sidebar" />
    <v-content class="fill-height">
      <router-view />
    </v-content>
  </div>
</template>

<script>
import HomeSidebar from '../components/home/HomeSidebar.vue';

export default {
  name: 'HomePage',
  components: { HomeSidebar }
};
</script>

<style lang="scss" scoped>
.home-page {
  height: 100%;
}
</style>
