<template>
  <home-sidebar-select-element :mini-variant="miniVariant">
    <template v-slot:mini-icon>
      <centered-icon class="sidebar-select__icon-wrapper">
        <notifications-icon :notifications-number="6" />
      </centered-icon>
    </template>
    <template v-slot:expand-icon>
      <centered-icon class="sidebar-select__icon-wrapper">
        <notifications-icon :notifications-number="6" />
      </centered-icon>
      <div class="sidebar-select__title-wrapper d-flex align-center">
        <span class="blue-grey--text text--lighten-1">Notifications</span>
      </div>
    </template>
  </home-sidebar-select-element>
</template>

<script>
import CenteredIcon from '../common/CenteredIcon.vue';
import NotificationsIcon from './NotificationsIcon.vue';
import HomeSidebarSelectElement from './HomeSidebarSelectElement.vue';

export default {
  name: 'NotificationsSidebarSelectElement',
  components: { HomeSidebarSelectElement, NotificationsIcon, CenteredIcon },
  props: {
    miniVariant: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/home/sidebar-select';
</style>
