<template>
  <div class="notifications-icon__wrapper">
    <v-icon class="blue-grey--text text--lighten-2" :size="28">mdi-bell-outline</v-icon>
    <div class="notifications-counter orange accent-4 d-flex justify-center align-center">
      <span class="notifications-counter__count white--text">{{ notificationsNumber }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationsIcon',
  props: {
    notificationsNumber: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
.notifications-icon {
  &__wrapper {
    position: relative;
    height: 25px;
  }
}

.notifications-counter {
  position: absolute;
  top: -7px;
  right: -5px;

  width: 19px;
  height: 19px;

  border-radius: 50%;

  &__count {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
}
</style>
